import { DataTypeEnum, DateTimeDisplay, FormAnswer, ValidationTypeEnum } from '@orion/fe-utils';
import {
    CalculatedValuesLanguageEnum,
    CalculationData,
    ConflictData,
    DataPoint,
    DataPointControlTypeEnum,
    DataPointInstanceSk,
    DataPointScopeEnum,
    DataPointTypeEnum,
    DbStudyActivty,
    ExpressionSk,
    LocalTime,
    RFC,
    S3File,
    StudyActivityStatusEnum,
    StudyAnswer,
    StudyTaskDefinition,
    SubjectStudyInstanceStatusEnum,
    TranscribableEnum,
    UnitsOfMeasureEnum,
    UnitsOfMeasureFEEnum,
    UserName,
} from 'common/graphql/types';
import { ExpressionVariableMap, FormulaValue, RoundingEnum } from 'common/types/commonTypes';
import { FilterItem } from 'common/viewcomp/types/filterTypes';
import { TFunction } from 'i18next';
import { Dictionary } from 'lodash';
import { CIRulesEngine, CIRulesEngineValidateResult } from 'module/activity/components/cirulesengine';
import { AnyAllCondition } from 'module/logic/LogicBuilder/types/logicConversionTypes';
import { ReactElement } from 'react';
import { RulesEngineGroupCondition } from '../utils/conditionConversion';
import { getDataPointFilterItem } from 'common/utils/dataPointApi';

export type ProcessingState = null | 'PENDING' | 'FAILED' | 'NEW';

export enum TaskResultStatusEnum {
    NOTSTARTED = 'NOTSTARTED',
    INPROGRESS = 'INPROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    RECALLED = 'RECALLED',
}

export interface NavigationRuleParams {
    id: string;
    message: string;
    navigateTo:
        | ButtonActionEnum.NONE
        | ButtonActionEnum.NEXTPAGE
        | ButtonActionEnum.PREVIOUSPAGE
        | ButtonActionEnum.CLOSEACTIVITY
        | ButtonActionEnum.END
        | string;
    navigateToActivity?: string;
}

export interface NavigationRule {
    type: 'navigationRule';
    params: NavigationRuleParams;
}

export enum NavigableTypes {
    Activity = 'ACTIVITY',
    Screen = 'SCREEN',
    Question = 'QUESTION',
}

export enum ActivityInstanceFileCategory {
    TRANSCRIPTION_DOCUMENT = 'TRANSCRIPTION_DOCUMENT',
    PATIENT_DOCUMENT = 'PATIENT_DOCUMENT',
}

export enum LibraryActivityStatusEnum {
    Draft = 'Draft',
    Published = 'Published',
}

export enum LayoutsEnum {
    RESPONSIVE_WEB = 'responsiveWebLayout',
    MOBILE = 'mobileLayout',
    TRANSCRIPTION = 'transcriptionLayout',
}

export interface CalculationError {
    name: string;
    message: string;
}

export interface EventInstanceStatus {
    pk: string;
    sk: string;
    version: number;
    eventGroupId: string;
    eventId: string;
    status: SubjectStudyInstanceStatusEnum;
}

export interface ActivityInstanceStatus {
    pk: string;
    sk: string;
    version: number;
    eventGroupId: string;
    eventId: string;
    activityGroupId: string;
    activityId: string;
    armId?: string;
    status: SubjectStudyInstanceStatusEnum;
}

export interface EditChecksSchema {
    contentId?: string;
    editChecks: {
        selected?: boolean;
        reason: string;
        fieldName: string;
        fieldTitle: string;
        editCheckValue: string;
    }[];
}

export interface EditChecksRow {
    selected?: boolean;
    reason: string;
    fieldName: string;
    editCheckValue: string;
    otherReason?: string;
    fieldTitle: string;
    initialValue?: string;
    newValue?: string;
}

export interface SubjectStudyActivityInstanceFile {
    pk?: string;
    sk?: string;
    fileName?: string;
    name?: string;
    s3Key?: string;
    fileSize?: number;
    contentId?: string;
    thumbnail?: string;
    category?: ActivityInstanceFileCategory;
    contentType?: string;
    hasDocumentation?: boolean;
}

export interface InputUpsertSubjectStudyActivityInstanceFile {
    contentId: string;
    category: ActivityInstanceFileCategory;
    s3Key: string;
    fileName: string;
    contentType: string;
    isDeleted: number;
}

export interface ActivityInstance extends ActivityInstanceStatus {
    studyId?: string;
    patientId?: string;
    answers: NormalizedValues[];
    pageStack: string[];
    eventDayOffset: number;
    eventTime?: string;
    startDateTime?: Date;
    endDateTime?: Date;
    transcribedDateTime?: Date;
    transcribedReason?: string;
    dataCollectionStartDateTime?: Date;
    dataCollectionStartTimeUnknown?: boolean;
    dataCollectionEndDateTime?: Date;
    dataCollectionEndTimeUnknown?: boolean;
    estimatedTime?: boolean;
    rescheduled?: number;

    //Started
    activityStartBy?: string;
    activityStartByName?: UserName;
    activityStartDate?: string;
    activityStartTime?: string;
    // Modified
    updatedAt?: Date;
    updatedBy?: string;
    updatedByName?: UserName; // Activity Instance last update by name
    //Ended
    activityEndDate?: string;
    activityEndTime?: string;
    activityEndBy?: string;
    activityEndByName?: UserName;
    completedAt?: string;
    completedAtLocal?: LocalTime;
    completedBy?: string;
    processingState?: ProcessingState;
    showSubmitConfirmation: boolean;
    dateWasConfirmedByUser: boolean;
    deleteTempAnswersProcessingState?: ProcessingState;
    deleteActivityInstanceProcessingState?: ProcessingState;
    updatedWithRFC?: RFC[];
    reasonForIncompleteId?: string;
    reasonForIncompleteText?: string;
    actualInstanceAnswers?: NormalizedValues[]; // To hold the answers locally for RFC
    taskInstanceId?: string;
    hashKey?: string;
    hashKeyEvent?: string;
    attachedFiles?: SubjectStudyActivityInstanceFile[];
    activityTranscribedByName?: UserName;
    activityTranscribedDate?: string;
    activityTranscribedTime?: string;
    dataCollectionStartDate?: string;
    dataCollectionStartTime?: string;
    dataCollectionEndDate?: string;
    dataCollectionEndTime?: string;

    activityTranscriptionReasonText?: string; // Used to store DCR number or transcription reason's text
    activityNoDocumentationReason?: string;
    activityNoDocumentationReasonText?: string;
    hasDocumentation?: boolean;
    hasOpenQueries?: boolean;
    hasRespondedQueries?: boolean;
    totalReviewedActivityReviews?: number;
    totalInProgressActivityReviews?: number;
}

export interface CreateSubjectStudyActivityInstance {
    createSubjectStudyActivityInstance: ActivityInstance;
}

export interface InputCreateSubjectStudyActivityInstanceVars {
    studyInstanceId: string;
    input: InputCreateSubjectStudyActivityInstance;
    localtime?: LocalTime;
}

export interface InputCreateSubjectStudyActivityInstance {
    studyId: string;
    patientId: string;
    armId: string;
    eventGroupId?: string;
    eventId?: string;
    activityGroupId: string;
    activityId: string;
    eventDayOffset: number;
    eventTime: string;
    activityStartDate?: string;
    activityStartTime?: string;
    activityTranscribedDate?: string;
    activityTranscribedTime?: string;
    activityTranscriptionReason?: string;
    activityTranscriptionReasonText?: string; // Used to store DCR number or transcription reason's text
    dataCollectionStartDate?: string;
    dataCollectionStartTime?: string;
    dataCollectionEndDate?: string;
    dataCollectionEndTime?: string;
    taskId?: string;
    taskInstanceId?: string;
    status: SubjectStudyInstanceStatusEnum;
    answers?: [InputUpsertSubjectStudyInstanceAnswer];
    hasCheckedEstimatedTime?: boolean;
    rescheduled?: number;
}

export interface SubjectStudyInstanceTaskResult {
    pk: string;
    sk: string;
    version?: number;
    isDeleted: number;
    tn?: string;
    data?: string;
    studyId?: string;
    patientId?: string;
    taskId?: string;
    taskInstanceId?: string;
    parentTaskInstanceId?: string;
    taskDefinition?: StudyTaskDefinition;
    taskTemplateId?: string;
    title?: string;
    description?: string;
    status?: TaskResultStatusEnum;
    dueDate?: string;
    dueDateLocal?: LocalTime;
    firstAvailableDate?: string;
    firstAvailableDateLocal?: LocalTime;
    dayOffset?: number;
    endDayOffset?: number;
    eventTime?: string;
    startedAt?: string;
    startedAtLocal?: LocalTime;
    startedBy?: string;
    startedByDevice?: string;
    startedByName?: string;
    completedAt?: string;
    completedAtLocal?: LocalTime;
    completedBy?: string;
    completedByDevice?: string;
    completedByName?: string;
    schema?: string;
    createdAt?: string;
    createdAtLocal?: LocalTime;
    createdBy?: string;
    createdByDevice?: string;
    updatedAt?: string;
    updatedAtLocal?: LocalTime;
    updatedBy?: string;
    updatedByDevice?: string;
    updatedWithRFC?: RFC[];
    createdByName?: string;
    updatedByName?: string;
    conflictData?: ConflictData;
    hasDocumentation?: boolean;
    usersWithTempActivities?: {
        userId?: string;
        userIdName: UserName;
    }[];
    isTranscribed?: boolean;
}

export interface InputCreateSubjectStudyInstanceTaskResult {
    taskId: string;
    parentTaskInstanceId: string;
    taskDefinitionId: string;
    taskTemplateId: string;
    title: string;
    description: string;
    status: TaskResultStatusEnum;
    dueDate: string;
    firstAvailableDate: string;
    dayOffset: number;
    endDayOffset: number;
    eventTime: string;
    startedAt: string;
    startedBy?: string;
    studyId: string;
    patientId: string;
    isTranscribed?: boolean;
    completedAt?: string;
    completedBy?: string;
    completedAtLocal?: LocalTime;
    rescheduled: number;
}

export interface CreateSubjectStudyInstanceTaskResult {
    createSubjectStudyInstanceTaskResult: SubjectStudyInstanceTaskResult;
}

export interface InputCreateSubjectStudyInstanceTaskResultVars {
    studyInstanceId: string;
    input: InputCreateSubjectStudyInstanceTaskResult;
    localtime?: LocalTime;
}

export interface InputUpdateSubjectStudyInstanceTaskResult {
    pk: string;
    sk: string;
    isDeleted: number;
    expectedVersion: number;
    title?: string;
    studyId?: string;
    taskInstanceId?: string;
    startedAt?: string;
    completedAt?: string;
    completedAtLocal?: LocalTime;
    completedBy?: string;
    status: TaskResultStatusEnum;
    isTranscribed?: boolean;
    hasDocumentation?: boolean;
    version?: number;
}

export interface InputUpdateSubjectStudyInstanceTaskResultVars {
    input: InputUpdateSubjectStudyInstanceTaskResult;
    localtime?: LocalTime;
}

export interface SubjectStudyActivityInstance {
    pk: string;
    sk: string;
    data: string;
    studyId: string;
    patientId: string;
    armId: string;
    eventGroupId: string;
    eventId: string;
    activityGroupId?: string;
    activityId?: string;
    eventDayOffset: number;
    eventTime: string;
    activityStartDate: string;
    activityStartTime: string;
    activityEndDate: string;
    activityEndTime: string;
    activityTranscribedDate: string;
    activityTranscribedTime: string;
    activityTranscriptionReason: string;
    dataCollectionStartDate: string;
    dataCollectionStartTime: string;
    dataCollectionEndDate: string;
    dataCollectionEndTime: string;
    taskId: string;
    status: SubjectStudyInstanceStatusEnum;
    subjectStudyInstanceStatus: string;
    completedAt: Date;
    answers: SubjectStudyInstanceAnswer[];
    activityConfig: DbStudyActivty;
    version: number;
    isDeleted: string;
    estimatedTime: boolean;
    rescheduled?: number;

    //Started
    activityStartBy: string;
    activityStartByName: UserName;
    erroredRules?: DataRule[];
    //Modified
    updatedAt: Date;
    updatedBy: string;
    updatedByName: UserName;
    //Ended
    activityEndBy: string;
    activityEndByName: UserName;
    updatedWithRFC?: [RFC];
    reasonForIncompleteId?: string;
    reasonForIncompleteText?: string;
    attachedFiles?: SubjectStudyActivityInstanceFile[];
    hasDocumentation?: boolean;
    taskInstanceId?: string;
    hashKey?: string;
    hashKeyEvent?: string;
    activityTranscribedByName?: UserName;
    activityTranscriptionReasonText?: string; // Used to store DCR number or transcription reason's text
    KPI?: {
        totalReviewedActivityReviews?: number;
        totalOpenQueries?: number;
        totalInProgressActivityReviews?: number;
        totalRespondedQueries?: number;
    };
}

export interface InputStudyAnswer {
    id: string;
    type: DataTypeEnum;
    text?: string;
    enText?: string;
    unitOfMeasureBE?: UnitsOfMeasureEnum;
    unitOfMeasureFE?: UnitsOfMeasureFEEnum;
    value?: string;
    valueString?: string;
    valueInt?: number;
    valueFloat?: number;
    valueBoolean?: boolean;
    valueDateTime?: string;
    valueDate?: string;
    valueTime?: string;
    valueCoding?: number | null;
    codedSelection?: number | null;
    unknownMonth?: boolean | null;
    unknownDay?: boolean | null;
    valueImperial?: number;
    dataPointInstanceSk?: DataPointInstanceSk;
}

export interface InputUpsertSubjectStudyInstanceAnswer {
    pk?: string;
    sk?: string;
    expectedVersion?: number;
    isDeleted?: number;
    questionId: string;
    questionText?: string;
    questionEnText?: string;
    friendlyName?: string;
    controlType?: string;
    dataPointKey?: string;
    answers: InputStudyAnswer[];
    calculationData?: CalculationData[];
    calculationTrigger?: string;
    calculationCode?: string;
    calculationError?: CalculationError;
    dataPointInstanceSks?: DataPointInstanceSk[];
    relatedExpressionIds?: ExpressionSk[];
}

export interface InputSaveSubjectStudyInstanceTempAnswer {
    questionId: string;
    questionText?: string;
    questionEnText?: string;
    friendlyName?: string;
    controlType?: string;
    dataPointKey?: string;
    calculationData?: CalculationData[];
    calculationTrigger?: string;
    calculationCode?: string;
    calculationError?: CalculationError;
    isDeleted?: number;
    answers: InputStudyAnswer[];
    dataPointInstanceSks?: DataPointInstanceSk[];
    relatedExpressionIds?: ExpressionSk[];
}

export interface InputSaveSubjectStudyActivityInstance {
    saveSubjectStudyTempActivityInstance: ActivityInstance;
}

export interface InputSaveSubjectStudyActivityInstanceVars {
    studyInstanceId: string;
    input: InputSubjectStudyTempActivityInstanceIds;
    answers?: InputSaveSubjectStudyInstanceTempAnswer[];
    localtime?: LocalTime;
}

export interface InputSubjectStudyTempActivityInstanceIds {
    studyId?: string;
    patientId?: string;
    armId?: string;
    eventGroupId?: string;
    eventId?: string;
    activityGroupId?: string;
    activityId?: string;
    eventDayOffset?: number;
    eventTime?: string;
    rescheduled?: number;
}

export interface ActivityTranslation {
    pk: string;
    sk: string;
    language: string;
    translationJSON: string;
    isDeleted: number;
}

export interface PatientLibraryContent {
    pk: string;
    sk: string;
    language: string;
    viewType: StudyContentViewTypeEnum;
    order: number;
    title: string;
    displayTitle: string;
    description: string;
    fileName: string;
    url: string;
    html: string;
    updatedAt: string;
    updatedBy: string;
    contentType: string;
    translations: {
        language: string;
        fileName: string;
        id: string;
        sk: string;
        contentType: string;
        title: string;
        url: string;
        html: string;
        description: string;
        pk: string;
        s3Key: string;
        version: number;
    }[];
    updatedByName: UserName;
    contentId: string;
    tags: string[];
    version: number;
}

export enum StudyContentViewTypeEnum {
    PATIENT = 'PATIENT',
    CLINICIAN = 'CLINICIAN',
}

interface NavigableType {
    type: NavigableTypes;
    activitySk: string;
}

export interface NavigableActivity extends NavigableType {
    type: NavigableTypes.Activity;
}

export interface NavigableScreen extends NavigableType {
    type: NavigableTypes.Screen;
    screenId: string;
}

export interface NavigableQuestion extends NavigableType {
    type: NavigableTypes.Question;
    screenId: string;
    questionId: string;
}

export type NavigableElement = NavigableActivity | NavigableScreen | NavigableQuestion;

export type NavigableList = { [key: string]: NavigableElement };

export interface DataRuleParams {
    elementId: string;
    i18nKey: string;
    message: string;
    softError: boolean;
    nonBlocking?: boolean;
    questionModified: boolean;
    questionTouched: boolean;
    questionVisited: boolean;
    pageId: string;
    values?: object;
    fieldName?: string;
    conditionType?: string;
    answerSk?: string;
    answerId?: string;
}

export interface DataRule {
    type: 'validationError';
    params: DataRuleParams;
}

export enum NavigationProcessMode {
    ReadOnlyMode = 'READONLYMODE',
    EditMode = 'EDITMODE',
}

export interface SubjectStudyInstanceAnswer {
    pk: string;
    sk: string;
    data?: string;
    version: number;
    isDeleted?: number;
    studyId?: string;
    patientId?: string;
    armId?: string;
    eventGroupId?: string;
    eventId?: string;
    activityGroupId?: string;
    activityId?: string;
    eventDayOffset?: number;
    eventTime?: string;
    questionId: string;
    questionText?: string;
    questionEnText?: string;
    friendlyName?: string;
    controlType: AnswerControlType_obsolete | QuestionFieldType;
    dataPointKey: string;
    answers: StudyAnswer[];
    createdAt?: string;
    createdBy?: string;
    updatedBy?: string;
    updateAt?: string;
    calculationData?: CalculationData[];
    calculationTrigger?: string;
    calculationCode?: string;
    calculationError?: CalculationError;
}

export interface ActivityInstanceHash {
    hashKey: string;
}

export interface AnswerForReview {
    id: string;
    text: string;
    value: string;
    unitOfMeasureFE?: UnitsOfMeasureFEEnum;
    valueDate: string;
    valueDateTime: string;
    valueTime: string;
}

export interface InstanceAnswerForReview {
    questionId: string;
    hashKeyActivity: string;
    answers: AnswerForReview[];
    controlType: AnswerControlType_obsolete | QuestionFieldType;
}

export enum AnswerControlType_obsolete {
    CALCULATED = 'CALCULATED',
    BLOODPRESSURE = 'BLOODPRESSURE',
    HEIGHT = 'HEIGHT',
    HVAS = 'HVAS',
    MULTIPLECHOICE = 'MULTIPLECHOICE',
    NUMERICRATING = 'NUMERICRATING',
    PULSE = 'PULSE',
    RESPIRATION = 'RESPIRATION',
    SELECT = 'SELECT',
    TEMPERATURE = 'TEMPERATURE',
    TEXT = 'TEXT',
    VVAS = 'VVAS',
    WEIGHT = 'WEIGHT',
    DATETIME = 'DATETIME',
}

export enum QuestionFieldType {
    CALCULATED = 'CALCULATED',
    TEXT = 'text',
    MULTIPLECHOICE = 'multiselect',
    SELECT = 'singleselect',
    DATETIME = 'datetime',
    BLOODPRESSURE = 'bloodPressure',
    HEIGHT = 'height',
    HVAS = 'horizontalvas',
    NUMERICRATING = 'numericScale',
    PULSE = 'pulse',
    RESPIRATION = 'respiration',
    TEMPERATURE = 'temperature',
    VVAS = 'verticalvas',
    WEIGHT = 'weight',
    IMAGECAPTURE = 'mobileImageCapture',
    DATA_POINT_DISPLAY = 'calculationDisplay',
}

export enum PressureType {
    SYSTOLIC = 'SYSTOLIC',
    DIASTOLIC = 'DIASTOLIC',
}

export enum UnitSystem {
    METRIC = 'METRIC',
    IMPERIAL = 'IMPERIAL',
}

export enum DataPointDisplayDataType {
    CALCULATED_VALUE = 'CALCULATED_VALUE',
    DATA_POINT = 'DATA_POINT',
}

export interface InputUpdateSubjectStudyActivityInstance {
    pk: string;
    sk: string;
    expectedVersion: number;
    isDeleted: number;
    status?: SubjectStudyInstanceStatusEnum;
    activityStartDate?: string;
    activityStartTime?: string;
    activityTranscribedDate?: string;
    activityTranscribedTime?: string;
    activityTranscriptionReason?: string;
    activityTranscriptionReasonText?: string; // Used to store DCR number or transcription reason's text
    dataCollectionStartDate?: string;
    dataCollectionStartTime?: string;
    dataCollectionEndDate?: string;
    dataCollectionEndTime?: string;
    answers?: InputUpsertSubjectStudyInstanceAnswer[];
    reasonForIncompleteId?: string;
    reasonForIncompleteText?: string;
    attachedFiles?: InputUpsertSubjectStudyActivityInstanceFile[];
    activityNoDocumentationReason?: string;
    activityNoDocumentationReasonText?: string;
    hasDocumentation?: boolean;
}

export interface UpdateActivityInstanceMutationVariable {
    input: InputUpdateSubjectStudyActivityInstance;
    rfc?: RFC[];
    localtime?: LocalTime;
}

export enum ButtonActionEnum {
    NEXTPAGE = 'nextPage',
    PREVIOUSPAGE = 'previousPage',
    CLOSEACTIVITY = 'closeActivity',
    TRIGGERACTIVITY = 'triggerActivity',
    LOGIC = 'logic',
    END = 'end',
    NONE = 'none',
}

type Normalizer = (values, config, t) => FormQuestionAnswer;

type TextDefault = {
    text: string;
};

type DateAndTimeDefault = {
    dateAndTimeYear: string;
    dateAndTimeMonth: string;
    dateAndTimeDay: string;
    dateAndTimeHours: string;
    dateAndTimeMinutes: string;
    dateAndTimeAmPm: string;
    dateAndTimeUnknownMonth?: boolean | null;
    dateAndTimeUnknownDay?: boolean | null;
};

type MultiChoiceDefault = {
    multichoice: string;
};

type SelectDefault = {
    select: string;
};

type BloodPressureDefault = {
    systolic: string;
    diastolic: string;
};

type TemperatureDefault = {
    temp: string;
    temperatureUnit: string;
};

type PulseDefault = {
    pulse: string;
};

type HeightDefault = {
    meters: string;
    centimeters: string;
    inches: string;
    feet: string;
    height: string;
};

type WeightDefault = {
    inputUnit: string;
    kilograms: string;
    grams: string;
    pounds: string;
    ounces: string;
    weight: string;
};

type RespirationDefault = {
    bpm: string;
};

type NRSDefault = {
    nrs: string;
};

type HVASDefault = {
    horizontalvas: string;
};

type VVASDefault = {
    verticalvas: string;
};

type CalculationDisplayDefault = {
    calculation: string;
};

type DefaultValues =
    | TextDefault
    | DateAndTimeDefault
    | MultiChoiceDefault
    | SelectDefault
    | BloodPressureDefault
    | TemperatureDefault
    | PulseDefault
    | HeightDefault
    | WeightDefault
    | RespirationDefault
    | NRSDefault
    | HVASDefault
    | VVASDefault
    | CalculationDisplayDefault;

export type ElementMeta = {
    normalizer: Normalizer;
    defaultValues: DefaultValues;
};

export enum DisplayActivityHistoryOptionsEnum {
    ENABLE = 'ENABLE',
    DISABLE = 'DISABLE',
}

export enum ResumableEnum {
    INCOMPLETE = 'INCOMPLETE',
    ALWAYS = 'ALWAYS',
    NEVER = 'NEVER',
}

export interface ActivityMatchData {
    armId: string;
    eventGroupId: string;
    eventId: string;
    activityGroupId: string;
    activityId: string;
    eventDayOffset: number;
    eventTime?: string;
}

export enum ActivityTranscribableEnum {
    REQUIRED = 'REQUIRED',
    OPTIONAL = 'OPTIONAL',
    FORBIDDEN = 'FORBIDDEN',
}

export type FormQuestionAnswer = {
    patientId: string | null;
    siteId: string | null;
    studyId: string | null;
    armId: string | null;
    eventGroupId: string | null;
    eventId: string | null;
    eventDayOffset: string | null;
    eventTime: string | null;
    activityId: string;
    activityGroupId: string;
    questionId: string;
    questionText: string;
    questionEnText: string;
    friendlyName: string;
    controlType: string;
    dataPoint: string | null;
    answers: FormAnswer[];
    dataPointInstanceSks?: DataPointInstanceSk[];
    relatedExpressionIds?: ExpressionSk[];
};

export interface NormalizedValues {
    pk?: string;
    sk?: string;
    version?: number;
    eventTime?: string;
    questionId: string;
    questionText?: string;
    questionEnText?: string;
    friendlyName?: string;
    controlType?: AnswerControlType_obsolete | QuestionFieldType;
    dataPoint?: string;
    dataPointKey?: string;
    answers: StudyAnswer[];
    isDeleted?: number;
    calculationData?: CalculationData[];
    calculationTrigger?: string;
    calculationError?: CalculationError;
    calculationCode?: string;
    fieldName?: string;
    dataPointInstanceSks?: DataPointInstanceSk[];
    relatedExpressionIds?: ExpressionSk[];
}

export enum ActivityTypeEnum {
    EPRO = 'EPRO',
    CLINRO = 'CLINRO',
}

export enum DeviceEnum {
    SAMSUNGGALAXYJ3 = 'Samsung Galaxy J3',
    SAMSUNGGALAXYA10 = 'Samsung Galaxy A10',
    SAMSUNGGALAXYA20 = 'Samsung Galaxy A20',
    SAMSUNGGALAXYA11 = 'Samsung Galaxy A11',
    APPLEIPHONE7 = 'Apple iPhone 7',
    APPLEIPHONE11 = 'Apple iPhone 11',
    APPLEIPHONE11PROMAX = 'Apple iPhone 11 Pro Max',
    GOOGLEPIXEL2XL = 'Google Pixel 2XL',
    REDMINOTE8 = 'Xiaomi Redmi Note 8',
    BLUEBIRDHF550 = 'Bluebird HF550',
    PATIENTWEB = 'Patient Web',
}

export interface NonActivityScreen {
    buttonText: string;
    buttonColor: string;
    buttonI18nKey: string;
    showScreen: boolean;
}

export interface CompletionScreen extends NonActivityScreen {
    showHeader: boolean;
    headerI18nKey: string;
    text: string;
    textI18nKey: string;
    showIllustration: boolean;
    imageName: string;
    imageS3Key: string;
}
export interface SummaryScreen extends NonActivityScreen {
    showHeader: boolean;
    text: string;
    textI18nKey: string;
    pinButtonToBottom: boolean;
    noAnswerProvidedText: string;
    noAnswerProvidedI18nKey: string;
    noImageProvidedText: string;
    noImageProvidedI18nKey: string;
}

export enum CopyrightOnEnum {
    INTRO = 'intro',
    ACTIVITY = 'activity',
    SUMMARY = 'summary',
    COMPLETION = 'completion',
}

export type CalculatedValuesBase = {
    name: string;
    language: CalculatedValuesLanguageEnum;
    code?: string;
    roundingPrecision?: number;
    roundingMode?: RoundingEnum;
    show: boolean;
    displayAsPercentage: boolean;
    variableMap?: ExpressionVariableMap[];
    isDeleted?: number;
    sf36V2?: IntegrationSetup;
    source?: string;
};

export type IntegrationSetup = {
    globalKey: string;
    groupID: string;
    groupLogin: string;
    siteLogin: string;
    surveyID: string;
};

export enum ExpressionIntegrationEnum {
    SF36V2 = 'sf36V2',
}

export type CalculatedValues = CalculatedValuesBase & {
    id: string;
    createdAtLocal?: Date | { timestamp: string; timezone: string };
    version?: number;
    isNew?: boolean;
    coreDataPoint?: ReturnType<typeof getDataPointFilterItem>;
};

export enum ExpressionTypeEnum {
    CALCULATED_VALUE = 'CALCULATED_VALUE',
    CROSS_FORM = 'CROSS_FORM',
}

export enum CrossFormTypeEnum {
    REVIEW_CONTROL = 'REVIEW_CONTROL',
    BUTTON_TRIGGER = 'BUTTON_TRIGGER',
}

export type CrossFormExpressionBase = {
    destination: { activityId: string; questionId: string };
    variableMap: ExpressionVariableMap[];
    type: ExpressionTypeEnum;
    crossFormType: CrossFormTypeEnum;
};

export type CrossFormExpression = CrossFormExpressionBase & {
    sk: string;
    pk: string;
    version: number;
};

// enriched cross form expression with destination activity name
export type UsedInCrossFormExpression = CrossFormExpressionBase & {
    destinationActivityName?: string;
    destinationCurrent?: boolean;
    destinationActivityGroupId?: string;
    destinationArmName?: string;
    destinationArmSk?: string;
};

export interface ActivityBaseModel {
    pk?: string;
    sk?: `Activity#${string}`;
    version?: number;
    isDeleted?: number;
    order?: number;
    name: string;
    title?: string;
    titleI18nKey?: string;
    description?: string;
    descriptionI18nKey?: string;
    transcribable?: TranscribableEnum;
    requireDataCollectionDates?: boolean;
    tags?: string[];
    status?: LibraryActivityStatusEnum | StudyActivityStatusEnum;
    s3Files?: S3File[];
    fontFamily?: string;
    fontWeight?: number;
    fontColor?: string;
    fontSize?: string;
    lineHeight?: string;
    activityType?: ActivityTypeEnum;
    layoutType?: ActivityLayoutEnum;
    defaultDevice?: DeviceEnum;
    resumable?: ResumableEnum;
    isLocked?: boolean;
    introductionScreen?: NonActivityScreen;
    completionScreen?: CompletionScreen;
    summaryScreen?: SummaryScreen;
    progressBar?: boolean;
    copyright?: string;
    copyrightI18nKey?: string;
    copyrightOn?: CopyrightOnEnum[];
    calculatedValues?: CalculatedValues[];
    currentTab?: string;
    activityId?: string;
    crossForms?: CrossFormExpression[];
    versionStr?: string;
    usedInCrossForms?: UsedInCrossFormExpression[];
    displayHistoryDetail?: DisplayActivityHistoryOptionsEnum;
    respondentType?: RespondentTypeEnum;
    isInterviewMode?: boolean | null;
}

export interface QuestionProperties {
    key: string;
    value: string;
}

export interface QuestionDefinition {
    id: string;
    order: number; // Order driven by layout container, don't believe this has a use
    type: string;
    text: string;
    friendlyName: string;
    dataPointKey: string;
    i18nKey: string;
    validations: Validator[];
    dataMappers: { key: string; value: string }[];
    choices: Choice[];
    rules: ActivityRule[];
    questionProperties?: QuestionProperties[];
}

export interface ActivityGroup {
    id: string;
    order: number;
    i18nKey: string;
    questions: QuestionDefinition[];
}

export interface StudyActivityLayout {
    id: string;
    type: string;
    screens: ActivityScreen[];
    screenWidth?: number;
    screenWidthLocked?: boolean;
}

export interface StudyActivityMobileLayout extends StudyActivityLayout {
    useGesture: boolean;
    pageButtons: boolean;
    titleAlignment: string;
    viewportWidth: string;
}

export interface ActivityLayout extends ActivityBaseModel {
    activityGroup: ActivityGroup;
    activityGroupId: string;
    responsiveWebLayout: StudyActivityLayout;
    mobileLayout: StudyActivityMobileLayout;
    transcriptionLayout?: StudyActivityLayout;
    translations: object;
    s3FileMap?: object;
    numberOfStudies?: number;
    screens?: ActivityScreen[];
    calculationDisplaysUpdateFlag?: boolean;
    questionsWithConditionalDisplay?: ActivityScreenElementLayout[];
}

export enum ActivityLayoutEnum {
    RESPONSIVEWEB = 'RESPONSIVEWEB',
    TABLET = 'TABLET',
    MOBILE = 'MOBILE',
    TRANSCRIPTION = 'TRANSCRIPTION',
}

export enum ActivityLayoutDropdownEnum {
    MOBILE = 'MOBILE',
    RESPONSIVEWEBSITE = 'RESPONSIVEWEBSITE',
    RESPONSIVEWEBPATIENT = 'RESPONSIVEWEBPATIENT',
}

export enum RespondentTypeEnum {
    PATIENT = 'PATIENT',
    SITE = 'SITE',
}

export enum ElementType {
    LAYOUT, //0
    SCREEN, //1
    LIST, //2
    TABLE, //3
    MATRIX, //4
    LABEL, //5
    TEXT, //6
    _DEPRECATED_NUMBER, //7
    DATETIME, //8
    RADIO, // SINGLE CHOICE	//9
    SELECT, // SINGLE CHOICE	//10
    CHECKBOX, //MULTI CHOICE	//11
    MULTICHOICE, //12
    _DEPRECATED_NUMERICRATING, //13
    SLIDINGSCALE, //14
    VERTICALVAS, //15
    BUTTON, //16
    BLOOD_PRESSURE, //17
    TEMPERATURE, //18
    PULSE, //19
    WEIGHT, //20
    HEIGHT, //21
    RESPIRATION, //22
    NUMERIC_SCALE, //23
    HORIZONTALVAS, //24
    MATRIX_MULTICHOICE, //25
    REVIEWCONTROL, //26
    IMAGE_CAPTURE,
    DATA_POINT_DISPLAY,
}

export interface Validator {
    type: ValidationTypeEnum;
    value: string | { fact: string; path: string };
    text: string;
    i18nKey?: string;
    warningOnly?: boolean;
    path?: string;
    comparePath?: string;
    compareFact?: string;
    name?: string;
    // Needed to correctly render Comparison Data Point dropdown. Selected data
    // point id is also stored in compareFact. Not saved to the BE.
    compareDataPoint?: FilterItem<DataPoint>;
}

export interface Choice {
    id: string;
    order: number;
    text: string;
    i18nKey: string;
    value: string;
    codedValue?: string;
    imageOnly?: boolean;
    imagePlacement?: string;
    multiSelectOverride?: boolean;
    imageWidth?: string;
    imageHeight?: string;
    imageS3Key?: string;
}

export enum ActivityRuleEventType {
    TriggerQuestion = 'triggerQuestion',
}
export interface ActivityRuleLeafCondition {
    fact: string;
    operator: string;
    value: string | Record<string, unknown>;
    path: string;
    id?: string;
}

export interface ActivityRuleEvent {
    type: string;
    params: { [key: string]: string };
}

export type ActivityRuleCondition = AnyAllCondition<ActivityRuleLeafCondition>;

export interface ActivityRule {
    conditions: ActivityRuleCondition;
    event: ActivityRuleEvent;
    priority?: number;
}

export interface ActivityTriggerQuestionRule extends ActivityRule {
    event: {
        type: ActivityRuleEventType.TriggerQuestion;
        params: null;
    };
}

export interface ActivityTriggerQuestionEngineRuleEvent {
    type: ActivityRuleEventType.TriggerQuestion;
    params: { questionId: string };
}

export interface ActivityTriggerQuestionEngineRule {
    conditions: RulesEngineGroupCondition;
    event: ActivityTriggerQuestionEngineRuleEvent;
}

export type CodingLogic = {
    id: string;
    primaryOperator: string;
    primaryValue: number;
    secondaryOperator: string;
    secondaryValue: number;
    storedValue: number;
};

export type ResponseOverride = {
    response: string;
    value: string;
    optionText: string;
    optioni18nKey: string;
    width?: string | null;
    hide: boolean;
    codedValue?: string;
};

export interface ActivityScreenElementLayout<DisplayProperties = Dictionary<any>> {
    id: string;
    order: number;
    type: ElementType;
    displayProperties: DisplayProperties;
    elements: ActivityScreenElementLayout[];
    name: string;
    friendlyName: string;
    i18nKey: string;
    text: string;
    validations: Validator[];
    dataMappers: { key: string; value: string }[];
    choices: Choice[];
    rules: ActivityRule[];
    required: boolean;
    activityGroupId: string;
    codingLogic?: CodingLogic[];
    useInCalculation: boolean;
    overrides?: ResponseOverride[];
    multipleselect?: boolean;
    togglePreview?: boolean;
    ciMappedField?: string;
    customerMappedField?: string;
    labelTexti18nKey?: string;
    labelText?: string;
    calculation?: string;
    unknownMonth?: boolean;
    unknownDay?: boolean;
    dateTimeDisplay?: DateTimeDisplay;
}

export interface ActivityScreen<DisplayProperties = Dictionary<any>> {
    id: string;
    type: ElementType;
    order: number;
    name: string;
    text: string;
    i18nKey: string;
    displayProperties: DisplayProperties;
    elements: ActivityScreenElementLayout[];
}

export interface DeviceLayoutInitialObject {
    id: string;
    type: ActivityLayoutEnum;
    screens: ActivityScreen[];
    titleAlignment?: string;
}
export enum DesignerPaneArea {
    DESIGNER,
    CONFIG,
}

export enum StudyActivityQuestionEnum {
    LABEL = 'label',
    BLOODPRESSURE = 'bloodPressure',
    BUTTON = 'button',
    DATETIME = 'datetime',
    DATE = 'date',
    TIME = 'time',
    HEIGHT = 'height',
    HORIZONTALVAS = 'horizontalvas',
    MULTISELECT = 'multiselect',
    NUMERICSCALE = 'numericScale',
    PULSE = 'pulse',
    RATINGSCALE = 'ratingscale',
    RESPIRATION = 'respiration',
    SINGLESELECT = 'singleselect',
    TEMPERATURE = 'temperature',
    TEXT = 'text',
    VERTICALVAS = 'verticalvas',
    WEIGHT = 'weight',
    REVIEWCONTROL = 'reviewcontrol',
    MATRIX_MULTICHOICE = 'matrixmultichoice',
    IMAGE_CAPTURE = 'mobileImageCapture',
    // Value kept for backwards compatibility
    DATA_POINT_DISPLAY = 'calculationDisplay',
    CALCULATION = 'calculation',
}

interface DesignerRuleEventParams {
    questionModified: boolean;
    questionTouched: boolean;
    questionVisited: boolean;
    pageId?: string;
    message: string;
    softError: boolean;
    i18nKey: string;
    elementId: string;
    conditionType: ValidationTypeEnum;
    elementType: ElementType;
    values?: Record<string, unknown>;
    fieldName: string;
    answerId?: string;
    nonBlocking?: boolean;
}

export interface DesignerRuleEvent {
    type: 'validationError';
    params: DesignerRuleEventParams;
}

export enum DeviceOS {
    ANDROID = 1,
    IOS = 2,
}

export interface DeviceSpecification {
    id: number;
    name: DeviceEnum;
    os: DeviceOS;
    diagonal: number;
    pixelHeight: number;
    pixelWidth: number;
    screenHeight: number;
    screenWidth: number;
    ppi: number;
    notificationBarHeight: number;
    activityHeaderHeight: number;
    appFooterHeight: number;
    osFooterHeight: number;
    translationKey: string;
}

export interface ElementPreviewProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parentConfig?: any;
    config: ActivityScreenElementLayout;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    designerOptions?: any;
    t: TFunction;
    errorHandler?: (id: string, isError: boolean) => void;
    onChange?: (value) => void;
    onKeyUp?: () => void;
    setLoadedElement?: (elementId: string) => void;
    rulesEngine?: CIRulesEngine;
    errorMessages?: DesignerRuleEvent[];
    disabled?: boolean;
    required?: boolean;
    conditionalDisplayTriggered?: boolean;
    initialValues?: object;
    pageId: string;
    friendlyNames?: Array<string>;
    fontWeight?: number;
    fontFamily?: string;
    activityLayout?: ActivityLayout;
    isStudyActivity?: boolean;
    activityLayoutPK?: string;
    deviceDetails?: DeviceSpecification;
    displayValue?: string | number;
    avoidAutosave?: boolean;
}
export enum ReviewDisplayType {
    INSTANCES = 'instances',
    TIMEFRAME = 'timeframe',
}

export enum ColumnWidthType {
    AUTO = 'auto',
    CUSTOM = 'custom',
}
export interface BorderProps {
    width?: string;
    style?: string;
    color?: string;

    listBorder?: string;
    listBorderWidth?: string;
    listBorderStyle?: string;
    listBorderColor?: string;
    listItemBorderWidth?: string;
    listItemBorderStyle?: string;
    listItemBorderColor?: string;
    listItemOddBackground?: string;
    listItemEvenBackground?: string;
}

export enum Operation {
    GREATERTHAN = 'greaterThan',
    LESSTHAN = 'lessThan',
    GREATERTHANINCLUSIVE = 'greaterThanInclusive',
    LESSTHANINCLUSIVE = 'lessThanInclusive',
    EQUALTO = 'equalTo',
    NOTEQUALTO = 'notEqualTo',
}

export interface ConfigValidationProps {
    config: ActivityScreenElementLayout;
    requiredFields?: string[];
    requiredDisplayProps?: string[];
    requiredOptionFields?: string[];
    requiredChoiceFields?: string[];
    requiredColumnFields?: string[];
    requiredDisplayPropsWithUnit?: string[];
    intOnlyDisplayProps?: string[];
    intOnlyDisplayPropsWithUnit?: string[];
    maxLengthDisplayProps?: { [prop: string]: number };
    maxValDisplayProps?: { [prop: string]: number };
    minValueDisplayPropsColumns?: { [prop: string]: number };
    mustBeHexDisplayProps?: string[];
    jsonProps?: string[];
    friendlyNames?: string[];
}

export enum DataCollectionMode {
    DESIGNER = 'DESIGNER',
    EDIT = 'EDIT',
    READ = 'READ',
}

export enum ControlAlternateViewEnum {
    REVIEW_CONTROL_NO_INSTANCES = 'Review Control',
    IMAGE_CAPTURE_EDIT = 'Image Capture',
    DATA_POINT_DISPLAY_EMPTY_STATE = 'Data Point Display',
}

export enum ElementConditionalDisplay {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    HIDDEN = 'HIDDEN',
}

export enum RuleKeys {
    NAVIGATETOACTIVITY = 'navigateToActivity',
}

interface ApiRuleParam {
    key: string;
    value: string;
}

export interface ApiRule {
    conditions: ActivityRuleCondition;
    event: {
        type: string;
        params: ApiRuleParam[];
        priority?: number;
    };
}

export interface DesignerOptions {
    showEditControls?: boolean;
    enableDragDrop?: boolean;
    autoSave?: boolean; // If true, will automatically save (using the activityUrlParams var) any chances made locally prior to saving
    errorHandler?: Function;
    onConfigChange?: any;
    onTabChange?: Function;
    onElementSelected?: Function;
    removeRules?: Function;
    selectedElement?: ActivityScreenElementLayout;
    deviceDetails?: DeviceSpecification; // Outlines the specifications of the device type we want to preview with
    previewBrowser?: boolean;
    hideHiddenQuestionsInDesigner?: boolean;
}

export interface ResponsiveWidths {
    minWidth: string;
    maxWidth: string;
}

export interface DataChangeValue {
    questionModified?: boolean;
    normalizedValues?: NormalizedValues;
    modified?: Record<string, boolean>;
}

export interface ExecutionOptions {
    dataCollectionMode?: DataCollectionMode;
    defaultValues?: NormalizedValues[];
    questionsWithConditionalDisplay?: ActivityScreenElementLayout[];
    isSiteTranscribed?: boolean;
    page?: string;
    onDataChange: (value: DataChangeValue) => void;
    onFieldKeyUp?: () => void;
    onRuleTrigger?: (validateResult: CIRulesEngineValidateResult) => void;
    onNavTrigger?: (results: NavigationRule[]) => void;
    readonlyCover?: ReactElement;
    getRulesEngine?: (rulesEngine: CIRulesEngine) => void;
    layoutSize?: ResponsiveWidths;
    studyInstanceId?: string;
    setLoadedElement?: (elementId: string) => void;
    rulesEngineExtraFacts?: Record<string, unknown>;
}

export enum IdTypes {
    Question = 'Question',
    Choice = 'Choice',
    Column = 'Column',
}

export interface ScreenElements {
    screen: ActivityScreen;
    elementsInScreen: ActivityScreenElementLayout[];
}

export interface ScreenElementsRules {
    rule: ActivityRule;
    element: ActivityScreenElementLayout;
    screen: ActivityScreen;
}

export interface ScreenDisplayProperties {
    marginTop: string;
    marginBottom: string;
    marginLeft: string;
    marginRight: string;
}

export interface ScreenConfiguration {
    id: string;
    order: number;
    type: ElementType;
    name: string;
    text: string;
    i18nKey: string;
    displayProperties: ScreenDisplayProperties;
    deviceDetails: DeviceSpecification;
    previewBrowser?: boolean;
    elements: ActivityScreenElementLayout[];
    fullscreen?: boolean;
}

export interface ScaleItem {
    type: string;
    show: string;
    style?: string;
    positioning: string;
    alignment: string;
    order: number;
    scaleItemFontSize?: string;
}

export type HorizontalPositioning = 'inverted' | 'normal';

export type HorizontalScaleRanges =
    | 'lowerRange'
    | 'incrementRange1'
    | 'incrementRange2'
    | 'incrementRange3'
    | 'incrementRange4'
    | 'upperRange';

export interface ButtonStyle {
    backgroundColor: string;
    height: string;
    borderRadius: string;
    color: string;
    fontWeight: number;
    fontSize: string;
    width: string;
    margin: string;
}

export interface ActivityElementWithParent {
    element: ActivityScreenElementLayout;
    parent: ActivityScreen | ActivityScreenElementLayout;
}

export interface MatrixResponse {
    id: string;
    value: string;
    optionText: string;
    optioni18nKey: string;
    responseText: string;
    responsei18nKey: string;
    width: string;
}

export enum StudyActivityTriggerRuleTriggerEnum {
    ON_ACTIVITY_COMPLETE = 'ON_ACTIVITY_COMPLETE',
    ON_ACTIVITY_START = 'ON_ACTIVITY_START',
    ON_ANSWER_CONDITION = 'ON_ANSWER_CONDITION',
}

export enum ActivitySteps {
    INTRODUCTION = 'Introduction',
    ACTIVITY = 'Activity',
    SUMMARY = 'Summary',
    COMPLETION = 'Completion',
}

export enum UnknownDateProps {
    UNKNOWN_MONTH = 'unknownMonth',
    UNKNOWN_DAY = 'unknownDay',
}

export type AnswersResponse = {
    questionsToSave: { [key: string]: boolean };
    tempAnswers?: NormalizedValues[];
};

export type BranchingLogicResponse = {
    invalidAnswers: NormalizedValues[];
    validAnswers: string[];
    visited?: boolean;
};

export enum CalculationErrorType {
    DATA = 'DataError',
    SYNTAX = 'SyntaxError',
    INPUT = 'InvalidAPICall',
}

export interface DataPointInstance {
    pk: `StudyInst.${string}`;
    sk: DataPointInstanceSk;
    dataPointKey: `DataPoint.${string}`;
    answers: StudyAnswer[];
}

export interface DataPointInstanceAnswers {
    [dataPointId: `DataPoint.${string}`]: { answers: InputStudyAnswer[]; sk: DataPointInstanceSk };
}

export interface CalculationAnswers {
    [id: string]: InputUpsertSubjectStudyInstanceAnswer | DataPointInstanceAnswers;
}

export type ActivityInitialValues = { 'Question': { [uuid: string]: { [fieldType: string]: number | string } } };

export type CalculationResults = { [key: `Question.${string}`]: number };

export interface InputSubjectStudyTempActivityInstanceIds {
    studyId?: string;
    patientId?: string;
    armId?: string;
    eventGroupId?: string;
    eventId?: string;
    activityGroupId?: string;
    activityId?: string;
    eventDayOffset?: number;
    eventTime?: string;
    rescheduled?: number;
}

export enum ControlValueEnum {
    INT = 'valueInt',
    FLOAT = 'valueFloat',
    IMPERIAL = 'valueImperial',
    CODING = 'valueCoding',
    CODED_SELECTION = 'codedSelection',
    STRING = 'valueString',
    DATE = 'valueDate',
    TIME = 'valueTime',
    DATE_TIME = 'valueDateTime',
    TEXT = 'text',
}

export interface InputCompleteSubjectStudyInstanceTaskResult {
    studyId: string;
    taskId: string;
    taskInstanceId?: string;
    parentTaskInstanceId?: string;
    title: string;
    description: string;
    status: TaskResultStatusEnum;
    dueDateLocal?: LocalTime;
    firstAvailableDateLocal?: LocalTime;
    startedAtLocal: LocalTime;
    completedAtLocal: LocalTime;
    dayOffset?: number;
    endDayOffset?: number;
    eventTime?: string;
    answers?: InputUpsertSubjectStudyInstanceAnswer[];
    attachedFiles?: InputUpsertSubjectStudyActivityInstanceFile[];
}
export interface DataPointItem {
    dataPointId: `DataPoint.${string}`;
    controlType: DataPointControlTypeEnum;
    answerField: `DataPoint.${string}`;
    text: string;
    key?: string;
    dataPointType?: DataPointTypeEnum;
    dataPointScope?: DataPointScopeEnum;
}

export type DataPointsList = {
    [key: string]: DataPointItem;
};

export type EnrichedFormulaValue = FormulaValue & { controlType?: DataPointControlTypeEnum };
export type QuestionList = { [questionCode: string]: { text: string; questionId: string; answerField: string } };
export type FormulaValueWithPath = {
    [key: string]: Partial<EnrichedFormulaValue & { path: `DataPoint.${string}` | `Question.${string}` }>;
};
export type CalulatedValueData = DataPointsList & QuestionList;

export enum CalculatedValuePrefix {
    QUESTION = 'Q',
    DATA_POINT = 'D',
}

export type EnrichedVariableMap = ExpressionVariableMap & { controlType?: DataPointControlTypeEnum };
